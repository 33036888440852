.container {
	display: flex;
	height: 64px;
	border-bottom: 1px solid currentColor;
}

.container.noBorder {
	border-bottom-style: none;
}

.img {
	height: 35px;
	width: auto;
	margin-top: 14px;
	margin-left: 19px;
}

.closeIcon {
	height: 24px;
	width: 24px;
	margin-top: 20px;
	margin-left: 25px;
	margin-right: 5px;
	fill: currentColor;
}

.nav {
	flex: 1;
	display: flex;
	align-items: stretch;
}

.navChild {
	display: block;
	margin-top: 18px;
	margin-left: 30px;
}

.buttonTab {
	padding-bottom: 21px;
    background: transparent;
    border: none;
}

.navChildBorderRight {
	border-right: 1px solid currentColor;
    padding-right: 26px;
    /* does not combine neatly with navChildSelected */
    margin-bottom: 16px;
}

.navChildSelected {
	border-bottom: 3px solid currentColor;
}

.greeting, .logout {
	margin-top: 16px;
	margin-bottom: 16px;
	margin-right: 20px;
}

.greeting {
	padding-right: 20px;
	border-right: 1px solid currentColor;
}

