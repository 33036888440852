.history {
  max-height: 500px;
  padding-top: 40px;
  padding-bottom: 40px;
  border: 1px solid rgba(231, 233, 236, 1);
  border-radius: 2px 2px 0 0;
  overflow-x: hidden;
  overflow-y: scroll;
  color: black;
}

.disconnectWarning {
  position: fixed;
  top: -100px;
  left: 0;
  right: 0;
  height: 60px;
  background-color: hsla(0, 50%, 50%, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
  transition: 500ms transform, 500ms opacity;
  opacity: 0;
  transform: translate(0, 0);
}

.showDisconnectWarning {
  opacity: 1;
  transform: translate(0, 100px);
}

/**
 * MessageItem styles
 */

.message_row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 16px;
}

.many_first.message_row,
.many_middle.message_row {
  margin-bottom: 2px;
}

.day_header {
  width: 100%;
  padding-left: 66px;
  padding-top: 27px;
}

.day_header_hr {
  border-top: 1px solid rgba(231, 233, 236, 1);
}

/* Base message styles (defaults to "messages from others") */

.text .message,
.actionButton .message {
  max-width: 75%;
  border-radius: 18px;
  padding: 6px 16px;
  background-color: rgba(237, 238, 238, 1);
  font-size: 18px;
  line-height: 21px;
}

.event_urgent_high .message,
.event_urgent_medium .message {
  margin-left: 19px;
  font-size: 28px;
  line-height: 34px;
  font-weight: 500;
}

.event_icon {
  position: relative;
  margin-left: 9px;
  width: 38px;
  height: 38px;
  padding: 7px;
  border-radius: 19px;
}

.event_urgent_high .event_icon {
  background-color: rgba(232, 21, 21, 0.1);
}

.event_urgent_high .event_icon:before {
  display: block;
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 68px;
  height: 68px;
  border-radius: 34px;
  background-color: rgba(249, 197, 197, 0.2);
}

.event_urgent_high .event_icon:after {
  display: block;
  content: "";
  position: absolute;
  top: -30px;
  left: -30px;
  width: 98px;
  height: 98px;
  border-radius: 49px;
  background-color: rgba(253, 237, 237, 0.2);
}

.event_urgent_high .event_icon {
  fill: rgba(232, 21, 21, 1);
}

.event_urgent_medium .event_icon {
  fill: rgba(255, 149, 52, 1);
}

.message_leftspacer {
  flex: auto;
  min-width: 1px;
}

.message_name {
  width: 100%;
  margin-left: 68px;
}

.message_name,
.message_time,
.day_header {
  font-size: 14px;
  color: rgba(177, 183, 194, 1);
  line-height: 16px;
  margin-bottom: 5px;
}

.message_avatar {
  display: block;
  height: 30px;
  width: 30px;
  margin-left: 11px;
  margin-right: 11px;
  align-self: flex-end;
  border-radius: 15px;
  background-color: #aaa;
}

.message_time {
  margin: 0 11px;
}

/* Customizations for multiple messages from the same person */

.text.many_first .message {
  border-radius: 18px 18px 18px 4px;
}

.text.many_middle .message {
  border-radius: 4px 18px 18px 4px;
  margin-left: 52px;
}

.text.many_last .message {
  border-radius: 4px 18px 18px 18px;
  margin-left: 52px;
}

.current_user.text.many_first .message {
  border-radius: 18px 18px 4px 18px;
}

.current_user.text.many_middle .message {
  border-radius: 18px 4px 4px 18px;
  margin-left: 0;
}

.current_user.text.many_last .message {
  border-radius: 18px 4px 18px 18px;
  margin-left: 0;
}

/* Customizations for messages from current user */

.current_user .message {
  order: 1;
  background-color: rgba(5, 122, 255, 1);
  color: #ffffff;
  margin-right: 21px;
}

/* Other message types */

.actionButton .message {
  background: none;
  border: 1px solid rgba(5, 122, 255, 1);
  color: rgba(5, 122, 255, 1);
}

.event_urgent_high .message,
.event_urgent_medium .message {
  background: none;
}

/**
 * MessageComposer styles
 */

.composer {
  margin-bottom: 24px;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  border: 1px solid #aaa;
  border-radius: 0 0 4px 4px;
  transition: 400ms border-color;
}

.composerFocused {
  border-color: blue;
}

.textarea {
  width: 100%;
  height: 115px;
  padding: 8px 16px;
  font-size: 18px;
  line-height: 21px;
  resize: none;
  border: none;
}

.blobarea {
  width: 100%;
  height: 115px;
  padding: 10px 0 0 10px;
  line-height: 21px;
  border: none;
}

.audioarea {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconContainer {
  background-color: rgba(5, 122, 255, 1);
  border: 1px solid rgba(5, 122, 255, 1);
  margin-top: -3px;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
}

.icons {
  color: white;
}

.redIconContainer {
  background-color: red;
  border: 1px solid red;
  margin-top: -3px;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
  color: black;
}

.sendButton {
  position: absolute;
  top: 18px;
  right: 20px;
  height: 20px;
  width: 24px;
  opacity: 0.5;
  transition: 0.5s opacity;
}

.composerFocused .sendButton {
  opacity: 1;
}

.actionButtons {
  padding-left: 18px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.actionButtons .message {
  cursor: pointer;
}

.error {
  margin-left: 18px;
  margin-bottom: 2px;
  font-weight: bold;
  color: hsl(0, 75%, 50%);
}
