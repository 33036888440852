.container {
	min-height: 100vh;
	max-width: 100% !important;
	display: flex;
	flex-flow: column;
	align-items: center;
	background-color: #f8fafe;
}

.logo {
	display: block;
	height: 55px;
	width: auto;
	margin-top: 80px;
	margin-left: auto;
	margin-right: auto;
}

.title {
	display: block;
	margin-top: 3px;
	margin-bottom: 65px;
	font-size: 28px;
	text-align: center;
	font-weight: 500;
}


