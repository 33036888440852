.container {
	margin-bottom: 40px;
	padding: 35px 30px;
	background-color: white;
	box-shadow: 0 2px 10px 0 rgba(177,183,194,0.3);
	border-radius: 12px;
	border: 1px solid #E7E9EC;
}

.container :global(h1) {
	font-size: 22px;
	margin-top: 0;
	margin-bottom: 40px;
	text-align: center;
}

.container :global(label) {
	margin-bottom: 30px;
	max-width: 70vw !important;
}

.container :global(input) {
	width: 370px !important;
	max-width: 100% !important;
	display: block;
}

.container :global(Button) {
	width: 100%;
}