.button {
    background-color: currentColor;
    border-radius: 12px;
    cursor: pointer;
    display: inline-block;
    font-size: 1.5rem;
    height: 3.8rem;
    letter-spacing: .05rem;
    line-height: 3.8rem;
    padding: 0 2rem;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    border-style: none;
}

:global(label) + .button {
    margin-top: 1.7rem;
}

.button + .button {
    margin-left: 2.3rem;
}

.button:hover,
.button:active {
    opacity: .8;
}