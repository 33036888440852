.form {
	margin-top: 4.9rem;
	margin-left: auto;
	margin-right: auto;
	max-width: 57rem;
}

.tabbedSection {
	margin-bottom: 30px;
}

.firstTab {
	margin-left: 0;
}